p {
    text-align: center;
    padding-top: 40px;
}

.clickedNode.project {
    fill: #FFFFFF;
}

.clickedNode.database {
    fill: #327DE6;
}

.clickedNode.schema {
    fill: #62AEE6;
}

.clickedNode.table {
    fill: #6EB5E7;
}

.clickedNode.column {
    fill: #B7D3E7;
}