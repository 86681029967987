// ======================= MAIN =======================

$sidebar-expanded: 270px;
$sidebar-collapsed: 0px;

body {
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
  }

.navigation {
    .app-content {
        width: 100%;
        &.sidebar-expanded {
            height: 100%;
            padding-left: $sidebar-expanded !important;
        }

        &.sidebar-collapsed {
            height: calc(100vh - $header-height);
            padding-left: $sidebar-collapsed !important;

        }
    }
}

// ======================= ENVHEADER =======================

.header-text {
    width: 100%;
    text-align: center;    
}

// ======================= HEADER =======================

.tool-bar {
    //max-width: breakpoints(xl); 
    /*
    commented out because when the page was large enough the login button was not being displayed properly on the right side of the screen. 
    Might be what we want so could be changed back
    */
    height: $header-height !important;


    .title {
        flex-grow: 1;
    }
}

.drawer {
    margin-top: 16px !important;
}

.drawer-link {
    padding: 5px;
}

.drawer-link-sub {
    margin-left: 30px;
}

.menu-item {
    display: flex;
    justify-content: center;
}

.account {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px auto;
}

// ======================= SIDEBAR =======================

.sidebar {
    &.expanded {
        .MuiDrawer-paper {
            width: $sidebar-expanded;
        }
    }

    .MuiDrawer-paper {
        width: $sidebar-collapsed;
        background: palette(primary, main);
        color: palette(primary, contrastText);
        border: none;
        overflow-x: hidden !important;
    }
    .MuiList-root {
        overflow-y: auto;
        max-height: calc(100vh - 150px)
    }

    .MuiDivider-root {
        background-color: darken(palette(primary, light), 15%);
    }

    .MuiSvgIcon-root {
        color: white;
    }

    .MuiListItemIcon-root {
        min-width: 40px;
    }

    .sidebar-header {
        height: $header-height;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .sidebar-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
}

// ======================= FOOTER =======================

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-color: $tertiary-color;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1200;
}

.metadata {
    @include typography(body2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.metadata-line {
    @extend .metadata;
    flex-direction: row;
    padding: 0px 10px;

    p:first-child {
        padding-right: 8px;
    }
}

.metadata-column {
    @extend .metadata;
    flex-direction: column;
}