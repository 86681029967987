.overallContainer {
    display: flex;
    flex: 1 100%;
    align-self: stretch;
}

.comparisonContainer {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-self: stretch;
    transition: margin-left 0.3s;
}

.treeContainer {
    display: flex;
    flex: 1 100%;
    flex-direction: column;
    padding: 0 1%;
}

.divider {
    display: flex;
    width: 2px;
    background-color: #213353;
    height: calc(100vh - 51px);
    opacity: 30%;
}

.buttonContainer {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 10px;
}

.sidebar {
    flex: 0 0 0;
    flex-direction: column;
    overflow: hidden;
    transition: flex 0.3s;
}

.sidebar.expanded {
    flex: 0 0 30%;
    padding-right: 1vw;
}