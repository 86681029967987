.message-snackbar {
    &.success {
        background-color: palette(success, main);
        color: palette(success, contrastText);
    }
    &.error {
        background-color: palette(error, main);
        color: palette(error, contrastText);
    }
    &.warning {
        background-color: palette(warning, main);
        color: palette(warning, contrastText);
    }
    &.general {
        background-color: palette(info, main);
        color: palette(info, contrastText);
    }
}