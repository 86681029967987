.management-form {
    [name='cloneProjectButton'] {
        background-color: #213353;
        color: white;
        border-radius: 5px;
    }

    [name='cancelClone'] {
        border-radius: 5px;
    }
}