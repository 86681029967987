.chips {
    display: flex;
    flex-wrap: wrap;
}

.chip {
    margin: 2px;
    background-color: palette(secondary, main) !important;
    color: palette(secondary, contrastText) !important;
}


.management-form {
    @extend .full-screen;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    .MuiCardHeader-root {
        padding: 20px 30px !important;
        border-bottom: 1px solid palette(greyscale, A100);
    }

    .MuiCardContent-root {
        padding: 30px !important;
    }

    .MuiCardActions-root {
        padding: 20px 30px !important;
        border-top: 1px solid palette(greyscale, A100);
        justify-content: space-between;
    }

    .card {
        @extend .centered;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        width: 785px;
        box-shadow: 1px 2px 5px rgb(163, 163, 163);
        align-self: center;
    }

    h2 {
        color: palette(primary, main);
        font-weight: 500;
        text-align: center;
        padding-bottom: 10px;
        width: 100%;
    }

    .form-content {
        display: flex;
        flex-direction: column;
        align-items: space-around;
        gap: 20px;
    }

    .form-row {
        justify-content: space-between;
        display: flex;
        gap: 20px;
    }

    .login-button,
    .create-project-button {
        margin-right: 10px;
    }

}

.user-header {
    @extend .flex;
    @extend .row;
    @extend .align-center;
    @extend .justify-space-between;
    padding: 15px;
    margin-bottom: 10px;
}