.selected-header {
    font-weight: 700 !important;
}

.control-bar {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    flex-direction: row;
    padding: 0 16px 0 16px;
}

.control-bar .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
}

.control-bar .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.css-cyhbg8-MuiInputBase-root-MuiTablePagination-select {
    justify-content: flex-end;
    align-items: center;
    margin-top: 43px;
}

.css-18sklqb-MuiToolbar-root-MuiTablePagination-toolbar {
    min-height: 100px !important;
}