.access-form {
    @extend .full-screen;
    @extend .centered;
    background-color: #f0f0f0;

    form {
        max-width: 500px !important;
        padding: 20px;

        img {
            margin-bottom: 10%;
        }

        h2 {
            color: palette(primary, main);
            font-family:Verdana, Geneva, Tahoma, sans-serif;
            font-weight: 500;
            text-align: center;
            padding-bottom: 10px;
            width: 100%;
        }

        a {
            color: palette(secondary, main);
            text-decoration: none !important;
            font-weight: 500;
        }

        .card {
            background-color: white;
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 5px;
            padding-right: 30px;
            padding-left: 30px;
            width: 850px;
            height: fit-content;
            box-shadow: 1px 2px 5px rgb(163, 163, 163);
        }

        [name='login'] {
            margin: 10px 0px;
            width: 70%; //change button size
            height: 40px;
            border-radius: 5px;
            background-color: #395080;
            display: block;
            margin-left: auto;
            margin-right: auto;
            box-shadow: none;
        }

        [name='resetPassword'] {
            margin-left: 10px;
            background-color: #395080;
        }

        .cancel-button {
            background-color: #CA4646;
            border-radius: 5px;
            color: white;
        }

        .password-button {
            //width: 50%; //change button size
            height: 40px;
            border-radius: 5px;
            text-align: right;
            box-shadow: none;

        }

        .option-links {
            @extend .flex;
            @extend .justify-center;
            width: 100%;
        }

        .right-buttons {
            @extend .flex;
            justify-content: right !important;
            width: 100%;
        }

    }
}