.project_tree{
    background-color: $tertiary-color;
    color: white;
    height: 100%;
    max-height: 551px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 5px;
    
    .sidebar-table-button{
        color: white;
        border-radius: 0px;
    }
}
.project_explorer{
    //Styles closed tab
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    resize: none;
    display: flex;
    height: 100%;
    align-items: center;

    .to-open-tab {
        cursor: pointer;
        background-color: $tertiary-color;
        height: 90px;
        width: 30px;
        display: flex;
        border: 2px black solid;
        border-left: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-top: calc($header-height);
        align-items: center;

        .right-arrow{
            color: white;
        }
    }
    .sidebar-drawer { 
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        //background-color: transparent;
        .wrapper {
            border-right: 2px black solid;
            height: 100%;
            display: flex;
            flex-direction: column;
            width: calc(100% - 30px);

            .empty-sidebar {
                //Remaining empty space below project tree
                // background-color: $tertiary-color;
                height: 100%;
                background-color: $tertiary-color;
            }

            .sidebar-footer{
                width: 100%;
                padding-bottom: 10px;
                padding-left: 3px;
                padding-right: 3px;
                background-color: $primary-color;
                color: white;
                //width: calc(100% - 30px);
                img{
                    margin-left: 10px;
                    width: 90%;
                }
            }
        }


        .tab-to-close-sidebar{
            //sidebar close button
            cursor: pointer;
            position: absolute;
            border: 2px black solid;
            border-left: $tertiary-color;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            width: 32px;
            height: 90px;
            margin-top: calc($header-height + 5px);
            right: 0px;
            background-color: $tertiary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
        }
    
    }

    .project-explorer-header{
        color: white;
        background-color: $tertiary-color;
        font-size: x-large;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;
        text-align: center;
        border-bottom: 1px white solid;
    }
}