body {
    margin: 0;
    background-color: palette(background, default);
    font-family: "Plus Jakarta Sans", "Roboto", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// ==================== COLORS =======================

.background-color {
    background-color: palette(background, default) !important;
    color: palette(background, contrastText) !important;
}
.background-color-paper {
    background-color: palette(background, paper) !important;
    color: palette(background, contrastText) !important;
}
.primary-color {
    background-color: palette(primary, main) !important;
    color: palette(primary, contrastText) !important;
}
.secondary-color {
    background-color: palette(secondary, main) !important;
    color: palette(secondary, contrastText) !important;
}
.success-color {
    color: palette(success, main) !important;
    color: palette(success, contrastText) !important;
}
.error-color {
    color: palette(error, main) !important;
    color: palette(error, contrastText) !important;
}
.error-color-contrast {
    background-color: palette(error, dark) !important;
    color: palette(error, contrastText) !important;
}

// ==================== FORMATTING =======================

.full-size {
    height: 100% !important;
    width: 100% !important;
}
.full-width {
    width: 100% !important;
}
.flex {
    display: flex !important;

    // Direction
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }

    // Vertical Alignment
    &.align-start {
        align-items: flex-start !important;
    }
    &.align-center {
        align-items: center !important;
    }
    &.align-end {
        align-items: flex-end !important;
    }

    // Horizontal Alignment
    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-center {
        justify-content: center !important;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.justify-space-between {
        justify-content: space-between !important;
    }

    // Combination
    &.center {
        justify-content: center !important;
        align-items: center !important;
    }
}
.aligned-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
    }
    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }
}

// ==================== SPACING =======================

.margin-auto {
    margin: auto;
}

// ==================== TEXT =======================

.text-align-left {
    text-align: left !important;
}
.text-align-center {
    text-align: center !important;
}
.text-align-right {
    text-align: right !important;
}
.text-weight-600 {
    font-weight: 600 !important;
}
.word-wrap {
    word-break: break-word;
}

// ==================== OTHER =======================

.cursor-pointer {
    cursor: pointer;
}
