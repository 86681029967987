.MuiFilledInput-root {
    border-radius: shape(borderRadius);
    input {
        border-radius: shape(borderRadius);
    }
    &:before {
        border-radius: shape(borderRadius);
        border-bottom: none !important;
    }
    &:after {
        border-radius: shape(borderRadius);
    }
}
