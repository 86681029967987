.overallContainer {
    margin-top: 10px;
    display: flex;
    height: 95;
}

.innerContainer {
    /* Stores topContainer and bottomContainer so that the entire Transformation detail doesn't appear at the bottom if I did flex-direction column in overAllContainer*/
    display: flex;
    flex: 1;
    /* Makes it take up all of overallContainer space*/
    flex-direction: column;
}

.topContainer {
    /* Stores legacy, modern, etc*/
    display: flex;
    flex-direction: row;
    margin-bottom:10px;
    /* It gives the container the flexibility to grow and shrink */
}

.bottomContainer {
    /* stores description and code */
    display: flex;
    flex-direction: column;

}

.legacyContainer,
.modernContainer {
    display: flex;
    flex-direction: column;
    /* ^ This makes the child items stack vertically */
    align-items: flex-start;
    /* ^ Aligns children along the start of the cross-axis (top in this case) */
    flex: 1;
    /* ^ It gives each container equal flexibility */
    height: 100%;
    /* ^ Make sure the container fills its parent */
    height: 38vh;
    /* ^ Makes sure the container takes up the whole height of the viewport */
}

.legacyContainer {
    margin-right: 5px;
}

.descriptionContainer, .codeContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 5px;
    height: 15vh;
}

.text-input {
    display: flex;
    flex: 1;
}

.legacyLabel,
.legacyList,
.modernLabel,
.modernList {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* This line is new. It makes sure these elements take up the entire width of their containers */
}

.modernList,
.legacyList,
.descriptionBox, .codeBox {
    overflow-y: auto;
    /* This adds a vertical scrollbar when the content overflows */
    flex: 1;
    /*the lists will take up the remaining vertical space within their parent containers, pushing other elements (if any) towards the top or bottom based on the align-items property. */


    border: 1px solid #ccc;
    /* The border itself should only be 1px */
    border-radius: 4px;
    /* How round the border is */
    padding: 8px;
    /*8 pixels of space between the background and the content so it isn't on the edge*/
    background-color: #f8f8f8;
    /* Distinct background from the background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    /* Keep the scrollbar visible at all times */
    scrollbar-width: thin;
    scrollbar-color: #ccc #f8f8f8;
}

.descriptionBox, .codeBox {
    /* I want the description box to look like legacy and modern box but without the padding*/
    padding: 0px;
    border: 0px;
    background-color: transparent;

}

.modernList::-webkit-scrollbar,
.legacyList::-webkit-scrollbar {
    width: 6px;
}

.modernList::-webkit-scrollbar-thumb,
.legacyList::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
    /* Add border-radius to make the thumb rounded */
}

.modernList::-webkit-scrollbar-track,
.legacyList::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 3px;
    /* Add border-radius to make the track rounded */
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
}