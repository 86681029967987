.treeStyle {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap; /* Added flex wrap */
}

.treeView {
    overflow-y: 'auto'; /*e*/
    height: 100%;
    width: 100%; /* Set to 100% by default */
    padding: 1vw; /* Use vw for dynamic padding */
    box-sizing: border-box;
    color: #333;
}

.treeView h1 {
    margin-bottom: 10px; /*padding for the titles LEGACY and MODERN */
    color: #444;
    font-size: 24px;
    text-transform: uppercase; /* All uppercase*/
}

.treeView .MuiTreeItem-root { /* Space each of these out */
    margin-top: 10px;
}

.treeView .MuiTreeItem-label { /* Make font bigger */
    font-size: 16px;
}

.treeView .MuiTreeItem-content {
    align-items: center;
}

.treeView .MuiCheckbox-root {
    padding: 0;
    margin-right: 5px;
}

.treeView .MuiTreeItem-iconContainer {
    color: #888;
}

.treeView .MuiTreeItem-label div {
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out
}

/* Disabling hover effect */
.treeView .MuiTreeItem-group:hover { // Targets the MuiTree-Item-group hover effect
    background-color: inherit; // Set the background color of hovered item to same as parent element, effectively removing it
}

/* Disabling selected effect */
.treeView .Mui-selected {
    background-color: inherit !important; // Set same to parent element, !important = take precedence over other potentially conflicting rules.
    color: inherit;
}

.treeView .MuiTreeItem-label div[style*="backgroundColor: #b0b6c0"] {
    color: #fff;
}