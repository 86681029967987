.button-pinch {
    color: #fff;
    margin: 0px 5px !important;
    &:hover {
        transition: all 0.3s ease-out;
        transform: scale(0.95);
        background-color: none;
    }
}

.button-outline {
    color: #fff !important;
    box-shadow: inset 0px 0px 0px 2px #fff !important;
    margin: 0px 8px !important;
    min-width: 100px;
    will-change: transform, filter;
    transform-style: preserve-3d;
    &:before {
        content: "";
        box-sizing: border-box;
        height: 20px;
        position: absolute;
        top: 15px;
        left: 10px;
        right: 10px;
        z-index: -1;
    }
}

.button-gradient {
    background: #c2e9fb;
    background: -moz-linear-gradient(top left, #c2e9fb 0%, #a1c4fd 100%);
    background: -webkit-linear-gradient(top left, #c2e9fb 0%, #a1c4fd 100%);
    background: linear-gradient(to bottom right, #c2e9fb 0%, #a1c4fd 100%);
    color: #22222b !important;
    margin: 0px 8px !important;
    min-width: 100px;
    will-change: transform, filter;
    transform-style: preserve-3d;
    &:before {
        content: "";
        box-sizing: border-box;
        height: 20px;
        position: absolute;
        top: 12px;
        left: 5px;
        right: 5px;
        z-index: -1;
        background: #c2e9fb;
        background: -moz-linear-gradient(top left, #c2e9fb 0%, #a1c4fd 100%);
        background: -webkit-linear-gradient(top left, #c2e9fb 0%, #a1c4fd 100%);
        background: linear-gradient(to bottom right, #c2e9fb 0%, #a1c4fd 100%);
        filter: blur(8px) opacity(0.5);
    }
}
